<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center" v-if='!pdfLink'>
          <div class="spinner-border text-danger" role="status"></div>
          <div class="ms-3">
            <strong>Loading file...</strong>
          </div>
        </div>
        <div class="d-flex align-items-center" v-if='pdfLink'>
          <button type="button" class="btn btn-outline-dark" @click="goBack"><i class="fas fa-chevron-left"></i> Back to Content Hub</button>          
          &nbsp;&nbsp;<button type="button" class="btn btn-outline-dark" @click="downloadFile" v-bind:disabled='downloadInProgress'><i class="fas fa-file-download" v-if='!downloadInProgress'></i><i class="fas fa-spinner fa-spin" v-if='downloadInProgress'></i> Download file</button>
          &nbsp;&nbsp;<p class="mb-0">Tip: to print, click Download file then print using your PDF viewer.<br/>Click on each day to view 五不遇时 and 天显时刻.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-between py-2" v-if='pdfLink'>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goPrevMonth" v-if='hasPrevMonth'><i class="fas fa-backward"></i> Previous Month</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goNextMonth" v-if='hasNextMonth'>Next Month <i class="fas fa-forward"></i></button>
      </div>
    </div>
    <!-- <div class="row justify-content-center" v-if='pdfLink'>
      <div class="col-12 col-lg-6">
        <a href='https://jhmetaphysics.com/qm-workshop-2023-mar' target='_blank'>
        <img src="../assets/2023qimen-march.png"
        class="img-fluid" />
        </a>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <!-- <template v-if='pdfLink'>
          <VuePdfEmbed          
            :source='pdfLink'
            @loaded = "rendered_file"
            v-show="showFile"
          />
          <img :src="pdfLink" class="img-fluid" />          
        </template>   -->    
        <VuePdf
            v-if='pdfLink'
            :src='pdfLink'
          />  
      </div>
    </div>
    <div class="row justify-content-between" v-if='pdfLink'>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goPrevMonth" v-if='hasPrevMonth'><i class="fas fa-backward"></i> Previous Month</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goNextMonth" v-if='hasNextMonth'>Next Month <i class="fas fa-forward"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { Auth, API } from "aws-amplify";
import { onBeforeRouteUpdate } from 'vue-router'

export default {
  name: "Calendar",
  components: { },
  data: () => ({
    pdfLink: null,
    downloadInProgress: false,
    pdfStaticLink: null,
    showFile: false,
  }),
  watch: {},
  computed: {
    currentPos() {
      return this.$store.state.calendar.findIndex(e => e.S3ObjectKey == `${encodeURIComponent(this.$route.params.email)}/${this.$route.params.key}`)
    },
    hasPrevMonth() {
      return this.$store.state.calendar[this.currentPos - 1]
    },
    hasNextMonth() {
      return this.$store.state.calendar[this.currentPos + 1]
    }
  },
  setup() {
    onBeforeRouteUpdate((to) => {
      if (!to.params['key']) {
        let message = JSON.parse(to.params['email'])
        alert(`${message[0]}\n${message[1]}\n${message[2]}`)
        return false;
      }
    })
  },
  mounted() {
    this.getSignedLink(true);
    this.getSignedLinkStatic();
  },
  methods: {
    rendered_file() {
      this.showFile = true;
    },
    async getSignedLink(web = false, key = null, email = null) {
      this.pdfLink = null;
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + "_web.pdf") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
        //queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + ".png") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
      };
      try {
        /* const link = await API.get(apiName, path, myInit);
        const response = await fetch(link);
        this.pdfLink = await response.arrayBuffer() */
        this.pdfLink = await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push('/')
      }
    },
    async getSignedLinkStatic(web = false, key = null, email = null) {
      this.pdfStaticLink = null;
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + "_web.pdf") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
      };
      try {
        this.pdfStaticLink = await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push('/')
      }
    },
    goBack() {
      this.$router.push('/')
    },
    async downloadFile() {
      this.downloadInProgress = true;
      await fetch(this.pdfStaticLink)
      .then(res => res.blob())
      .then(blob => saveAs(blob, `JHMetaphysics_${this.$route.params.key}`))
      .then(() => this.downloadInProgress = false )
    },
    goPrevMonth() {
      const month = this.$store.state.calendar[this.currentPos - 1]
      this.$router.push(`/calendar/${month.S3ObjectKey}`);
      this.getSignedLink(true, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
      this.getSignedLinkStatic(false, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
    },
    goNextMonth() {
      const month = this.$store.state.calendar[this.currentPos + 1]
      this.$router.push(`/calendar/${month.S3ObjectKey}`);
      this.getSignedLink(true, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
      this.getSignedLinkStatic(false, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
    }
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
