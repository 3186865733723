<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center" v-if='!pdfLink'>
          <div class="spinner-border text-danger" role="status"></div>
          <div class="ms-3">
            <strong>Loading file...</strong>
          </div>
        </div>
        <div class="d-flex align-items-center" v-if='pdfLink'>
          <button type="button" class="btn btn-outline-dark" @click="goBack"><i class="fas fa-chevron-left"></i> Back to Content Hub</button>          
          &nbsp;&nbsp;<button type="button" class="btn btn-outline-dark" @click="downloadFile" v-bind:disabled='downloadInProgress'><i class="fas fa-file-download" v-if='!downloadInProgress'></i><i class="fas fa-spinner fa-spin" v-if='downloadInProgress'></i> Download file</button>
          &nbsp;&nbsp;<p class="mb-0">Tip: to print, click Download file then print using your PDF viewer.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <VuePdf
          v-if='pdfLink'
          :src='pdfLink'
        />
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { Auth, API } from "aws-amplify";

export default {
  name: "Viewer",
  components: { },
  data: () => ({
    pdfLink: null,
    downloadInProgress: false,
    showFile: false,
  }),
  watch: {},
  computed: {  },
  mounted() {
    this.getSignedLink();
  },
  methods: {
    async getSignedLink() {
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { key: `${encodeURIComponent(this.$route.params.email)}/${this.$route.params.key}`, itemType: 'bazi' },
      };
      try {
        this.pdfLink = await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push('/')
      }
    },
    goBack() {
      this.$router.push('/')
    },
    async downloadFile() {
      this.downloadInProgress = true;
      await this.getSignedLink()
      fetch(this.pdfLink)
      .then(res => res.blob())
      .then(blob => saveAs(blob, `JHMetaphysics_${this.$route.params.key}`))
      .then(() => this.downloadInProgress = false )
    }
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
