<template>
  <div class="container my-5">
    <div class="row" v-if="loadingStatus">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <div class="spinner-border text-danger" role="status"></div>
          <div class="ms-3">
            <strong>Loading payment status...</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!loadingStatus">
      <div class="d-flex align-items-center mb-3">
        <button type="button" class="btn btn-outline-dark" @click="goBack">
          <i class="fas fa-chevron-left"></i> Back to Content Hub
        </button>
      </div>
      <div class="col-12">
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
          <symbol
            id="check-circle-fill"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
            />
          </symbol>
          <symbol
            id="exclamation-triangle-fill"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </symbol>
        </svg>
        <div class="alert alert-success d-flex align-items-center" role="alert" v-if="paymentStatus == 'Success'">
          <svg
            class="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
          >
            <use xlink:href="#check-circle-fill" />
          </svg>
          <div>Thank you. Your payment is now being processed and your requested content will be available in 10 minutes. Please refresh the Content Hub to retrieve your latest purchase.</div>
        </div>
        <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="paymentStatus == 'Fail'">
          <svg
            class="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Danger:"
          >
            <use xlink:href="#exclamation-triangle-fill" />
          </svg>
          <div>Your payment has failed or has been cancelled.</div>
        </div>
        <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="paymentStatus == 'NoAccess'">
          <svg
            class="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Danger:"
          >
            <use xlink:href="#exclamation-triangle-fill" />
          </svg>
          <div>Unauthorized</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth, API } from "aws-amplify";
export default {
  name: "PaymentOutcome",
  components: {},
  data: () => ({
    loadingStatus: false,
    paymentStatus: null,
  }),
  watch: {},
  computed: {},
  mounted() {
    this.checkPaymentStatus();
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    async checkPaymentStatus() {
      this.loadingStatus = true;
      const apiName = "links";
      const path = "/paymentstatus";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          orderId: this.$route.params.orderId,
        },
      };
      try {
        // if paymentStatus is [] then error
        // if paymentStatus has results then handle accordingly

        // this.$route.name == 'Success' or 'Fail'
        // if success, then ask user to wait
        // if fail, then show payment link again
        const paymentObj = await API.get(apiName, path, myInit);
        if (paymentObj.length == 0) {
          this.paymentStatus = "NoAccess";
        } else {
          this.paymentStatus = this.$route.name;
        }
        this.loadingStatus = false;
      } catch (e) {
        console.log(e);
        alert(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
