<template>
  <div class="row pb-2" @click="showModal()">
    <div class="col cn pe-0">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-html="true"
        :title="getTitleHtml(qimen['奇门时盘']['八神'][pos])"
        >{{ qimen["奇门时盘"]["八神"][pos][1] }}<i class="far fa-star fa-xs" style="transform: translateY(-0.125em);" v-if='qimen["奇门时盘"]["八神"][pos][1] == "符"'></i></span
      >
    </div>
    <div class="col text-center px-0 align-self-center" v-if="qimen['奇门时盘']['门迫'][pos]">
      <span class="en-r text-muted align-text-top" style="font-size: 0.65rem;">
        迫
      </span>
    </div>
    <div class="col text-end cn ps-0">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-html="true"
        :title="getEleHtml(qimen['奇门时盘']['天盘left'][pos])"
        >{{ qimen["奇门时盘"]["天盘left"][pos] }}</span
      >
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-html="true"
        :title="getEleHtml(qimen['奇门时盘']['天盘'][pos])"
        >{{ qimen["奇门时盘"]["天盘"][pos] }}</span
      >
    </div>
  </div>
  <div class="row justify-content-between" @click="showModal()">
    <div class="col text-start align-self-center">
      <pre class="en-r text-muted pb-0 mb-0" style="font-size: 0.65rem" v-if="入墓击刑(pos)[0][1] && !入墓击刑(pos)[1] && (this.outcomePalace || this.askerPalace)"
        >{{ 入墓击刑(pos)[0][1][0] ? 入墓击刑(pos)[0][1][0] : '' }}
{{ 入墓击刑(pos)[0][1][1] ? 入墓击刑(pos)[0][1][1] : '' }}</pre
      >
      <pre class="en-r text-muted pb-0 mb-0 lh-1 overflow-hidden" style="font-size: 0.65rem" v-if="入墓击刑(pos)[0] && 入墓击刑(pos)[1] && (this.outcomePalace || this.askerPalace)"
        ><u v-if='入墓击刑(pos)[1][1]'>{{ 入墓击刑(pos)[1][1] ? 入墓击刑(pos)[1][0] : '    ' }}</u> <u v-if='入墓击刑(pos)[0][1]'>{{ 入墓击刑(pos)[0][1] ? 入墓击刑(pos)[0][0] : '    ' }}</u>
<span v-if='入墓击刑(pos)[1][1]'>{{ 入墓击刑(pos)[1][1] ? 入墓击刑(pos)[1][1][0] : '    ' }}</span> {{ 入墓击刑(pos)[0][1] ? 入墓击刑(pos)[0][1][0] : '    '  }} 
<span v-if='入墓击刑(pos)[1][1]'>{{ 入墓击刑(pos)[1][1] ? 入墓击刑(pos)[1][1][1] : '    ' }}</span> {{ 入墓击刑(pos)[0][1] ? 入墓击刑(pos)[0][1][1] : '    '  }}</pre
      >
    </div>
    <div class="col text-center cn" style="font-size: 1.6rem">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-html="true"
        :title="getTitleHtml(qimen['奇门时盘']['八门'][pos])"
        >{{ qimen["奇门时盘"]["八门"][pos][1] }}</span
      >
    </div>
    <div class="col text-end align-self-center">
      <pre class="en-r text-muted pb-0 mb-0" style="font-size: 0.65rem"
        >{{ 主客论(redBorderStem, qimen["奇门时盘"]["地盘"][pos])[0] }}
{{ 主客论(redBorderStem, qimen["奇门时盘"]["地盘"][pos])[1] }}</pre
      >
    </div>
  </div>
  <div class="row pt-2" @click="showModal()">
    <div class="col cn pe-0">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-html="true"
        :title="getTitleHtml(qimen['奇门时盘']['九星'][pos])"
        >{{ qimen["奇门时盘"]["九星"][pos][1] }}</span
      >
    </div>
    <div class="col text-center px-0 align-self-center">
      <span class="en-r text-muted" style="font-size: 0.65rem">
        {{ calendar }}
      </span>
    </div>
    <div class="col text-end cn ps-0">
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-html="true"
        :title="getEleHtml(qimen['奇门时盘']['地盘'][pos])"
        >{{ qimen["奇门时盘"]["地盘"][pos] }}</span
      >
    </div>
  </div>
  <div class="modal fade" tabindex="-1" :id="`modal_${this.pos}`" v-if="this.formation">
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
        <div class="modal-body en-r">
            <button type="button" class="btn-close float-start" style="visibility: hidden;" data-bs-dismiss="modal" aria-label="Close"></button>
            <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
            <p class="w-100 text-center">{{ this.positions[this.pos] }} {{ this.positionsChinese[this.pos] }}宫</p>            
            <span style="color: #613914;"><strong>{{ this.formation.格局 }}</strong> ({{this.qimen['奇门时盘']['天盘'][this.pos]}} + {{ this.qimen['奇门时盘']['地盘'][this.pos] }})</span>
            <table class="table table-sm table-borderless">
                <tr>
                    <td>生意 & 投资</td>
                    <td>
                        <template v-for="n in Math.abs(this.formation.生意投资)" :key="n">
                            <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formation.生意投资) == 1"></i>
                            <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formation.生意投资) == -1"></i>
                        </template>
                    </td>
                </tr>
                <tr>
                    <td>关系</td>
                    <td>
                        <template v-for="n in Math.abs(this.formation.关系)" :key="n">
                            <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formation.关系) == 1"></i>
                            <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formation.关系) == -1"></i>
                        </template>
                    </td>
                </tr>
                <tr>
                    <td>健康</td>
                    <td>
                        <template v-for="n in Math.abs(this.formation.健康)" :key="n">
                            <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formation.健康) == 1"></i>
                            <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formation.健康) == -1"></i>
                        </template>
                    </td>
                </tr>
            </table>
            <template v-if="this.qimen['奇门时盘']['天盘left'][this.pos]">
                <hr/>
                <span style="color: #613914;"><strong>{{ this.formationLeft.格局 }}</strong> ({{this.qimen['奇门时盘']['天盘left'][this.pos]}} + {{ this.qimen['奇门时盘']['地盘'][this.pos] }})</span>
                <table class="table table-sm table-borderless">
                    <tr>
                        <td>生意 & 投资</td>
                        <td>
                            <template v-for="n in Math.abs(this.formationLeft.生意投资)" :key="n">
                                <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formationLeft.生意投资) == 1"></i>
                                <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formationLeft.生意投资) == -1"></i>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>关系</td>
                        <td>
                            <template v-for="n in Math.abs(this.formationLeft.关系)" :key="n">
                                <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formationLeft.关系) == 1"></i>
                                <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formationLeft.关系) == -1"></i>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>健康</td>
                        <td>
                            <template v-for="n in Math.abs(this.formationLeft.健康)" :key="n">
                                <i class="fas fa-check-circle" style="color: #da1f1f;" v-if="Math.sign(this.formationLeft.健康) == 1"></i>
                                <i class="fas fa-times-circle" style="color: #000000;" v-if="Math.sign(this.formationLeft.健康) == -1"></i>
                            </template>
                        </td>
                    </tr>
                </table>
            </template>
            <template v-for="formation in this.formationSpecial" :key="formation.SK">
                <hr/>
                <table class="table table-sm table-borderless">
                    <tr>
                        <td><span style="color: #613914;"><strong>{{ formation.formationName }}</strong></span></td>
                        <td style="background-color: #da1f1f; color: white;" class="text-center">
                            吉
                        </td>
                    </tr>
                </table>
            </template>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
import qm_data from "@/assets/qimen_explanation.json";
//import { Tooltip, Modal } from "bootstrap";
import { Modal } from "bootstrap";

export default {
  name: "QimenPalaceWithFormation",
  props: {
    pos: Number,
    qimen: Object,
    outcomePalace: Boolean,
    redBorderStem: String,
    calendar: String,
    askerPalace: Boolean,
    palaceFormations: Array,
  },
  data: () => ({
    positions: ["SE", "S", "SW", "E", "C", "W", "NE", "N", "NW"],
    positionsChinese: ["巽", "離", "坤", "震", "", "兌", "艮", "坎", "乾"]
  }),
  mounted() {
    /* disabled to prevent tooltips from interfering with modal
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });*/
  },
  methods: {
    getTitleHtml(data) {
      const explain = qm_data.explanation[data];
      let html = `<div class='row'><div class='col text-start en-r my-1'>${explain["名"]}</div><div class='col en-r'><div class='tp-${explain["五行"]} text-center my-1 ms-auto'>${explain["五行"]}</div></div></div>`;
      html += `<div class='row'><div class='col text-start en-r my-1'>${explain["注解"]}</div></div>`;
      return html;
    },
    getEleHtml(data) {
      const explain = qm_data.天盘[data];
      if (explain) {
        return `<div class='row'><div class='col en-r'><div class='tp-${explain} text-center my-1 ms-auto'>${explain}</div></div>`;
      } else {
        return "";
      }
    },
    主客论(天盘, 地盘) {
      if (天盘 && this.outcomePalace) {
        if (this.qimen.timingText.includes("伏吟")) {
          return "利主";
        } else if (this.qimen.timingText.includes("反吟")) {
          return "利客";
        } else {
          if (天盘 == "庚" && 地盘 == "丙") {
            return "利客";
          } else if (地盘 == "庚" && 天盘 == "丙") {
            return "利主";
          } else {
            const rs =
              qm_data["五行论"][qm_data["天盘"][天盘]][qm_data["天盘"][地盘]];
            if (rs == "我克" || rs == "生我") {
              return "利客";
            } else return "利主";
          }
        }
      }
      return "";
    },
    入墓击刑(pos) {
      return [this.qimen['奇门时盘']['天盘'][pos], this.qimen['奇门时盘']['天盘left'][pos]].map(stem => {
        if (stem) {
          let output = []
          let text = ''
          if (qm_data['六仪击刑'][pos].includes(stem)) {
            text += '刑'
          }
          if (qm_data['入墓'][pos].includes(stem)) {
            text += '墓'
          }
          output.push(stem)
          if (text != '') {            
            output.push(text)
          }
          return output
        } else {
          return null
        }        
      })
    },
    showModal() {
        let modal = new Modal(document.getElementById(`modal_${this.pos}`))
        modal.show();
    }
  },
  computed: {
    formation() {
        const findFormation = this.palaceFormations.find((f) => {
          return f['SK'].split('#')[2] == this.qimen['奇门时盘']['天盘'][this.pos]
        })
        if (findFormation) {
          return findFormation.formation
        } else {
          return null;
        }
    },
    formationLeft() {
        if (this.qimen['奇门时盘']['天盘left'][this.pos]) {
          const findFormation = this.palaceFormations.find((f) => {
            return f['SK'].split('#')[2] == this.qimen['奇门时盘']['天盘left'][this.pos]
          })
          if (findFormation) {
            return findFormation.formation
          } else {
            return null;
          }
        } else {
            return null;
        }        
    },
    formationSpecial() {
      return this.palaceFormations.filter((f) => f.specialFormation )
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.cn {
  font-family: adobe-kaiti-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #613914;
}
.en-r {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.tp-金 {
  width: 1.25em;
  background-color: #d9cb9e;
  color: #212529;
}
.tp-木 {
  width: 1.25em;
  background-color: #1bbc2b;
  color: #212529;
}
.tp-水 {
  width: 1.25em;
  background-color: #4cb2f7;
  color: #212529;
}
.tp-火 {
  width: 1.25em;
  background-color: #ff8080;
  color: #212529;
}
.tp-土 {
  width: 1.25em;
  background-color: #ebaf70;
  color: #212529;
}
.tooltip-inner {
  max-width: 36em !important;
}
</style>
