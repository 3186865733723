import { createApp } from 'vue'
import App from './App.vue'
import SimpleAnalytics from "simple-analytics-vue";
import VuePdf from 'vue3-pdfjs'

import Amplify from 'aws-amplify';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure({});
applyPolyfills().then(() => {
  defineCustomElements(window);
});

import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap"

const app = createApp(App).use(store).use(router)
if (process.env.NODE_ENV == 'production') {
  app.use(SimpleAnalytics, { domain: 'sa.jhmetaphysics.com' })
}
app.use(VuePdf)
app.mount('#app')
