import { createStore } from 'vuex'

export default createStore({
  state: {
    userEmail: null,
    calendar: [],
  },
  mutations: {
    updateEmail (state, email) {
      state.userEmail = email
    },
    keepCalendar (state, calendar) {
      state.calendar = calendar
    },
    clearCalendar (state) {
      state.calendar = []
    },
  },
  actions: {
  },
  modules: {
  }
})
